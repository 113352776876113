<template lang="pug">
.page
  el-card(header="编辑公司信息")
    el-form(label-width="6em")
      el-form-item(label="标题")
        el-input(v-model="formData.name")
      el-form-item(label="简介")
        el-input(v-model="formData.desc")
      el-form-item(label="Logo")
        jj-imagePick(v-model="formData.logo")
      el-form-item(label="介绍图片")
        jj-imageList(v-model="formData.imageList" type="company")
      el-form-item(label="详细介绍")
        com-editor(v-model="formData.content" type="full")
      el-form-item(label="规模")
        el-input(v-model="formData.scale")
      el-form-item(label="融资状态")
        el-input(v-model="formData.financialStatus")
      el-form-item(label="所属行业")
        el-input(v-model="formData.industry")
      el-form-item(label="标签")
        jj-tagList(v-model="formData.tagList")
      el-form-item(label="地址")
        el-input(v-model="formData.contactInfo.address")
      el-form-item(label="电话")
        el-input(v-model="formData.contactInfo.tel")
      el-form-item(label="联系人")
        el-input(v-model="formData.contactInfo.people")
      el-form-item()
        el-button(type="primary" @click="handleSubmit") 保存

</template>

<script setup>
import { inject, onMounted, ref } from 'vue'
import comEditor from '@/component/editor/index.vue'
import { uploadImage, pickImageList } from '@/provider/upload'

const { router, route, fetch, message } = inject('global')

const formData = ref({
  _id: '',
  name: '',
  desc: '',
  logo: '',
  imageList: [],
  content: '',
  scale: '',
  financialStatus: '',
  industry: '',
  tagList: [],
  contactInfo: {
    address: '',
    tel: '',
    people: '',
    lat: '',
    lng: ''
  }
})

function handlePickLogo() {
  pickImageList({ count: 1, type: 'company' }).then(res => {
    if (res?.length) {
      formData.value.logo = res[0]
    }
  })
}

function handleSubmit() {
  if (!formData.value.name) {
    message.warning('名称不能为空')
  } else {
    const { _id, ...payload } = formData.value
    return Promise.resolve()
      .then(() => {
        if (_id) {
          return fetch.put(`/company/${_id}`, {
            ...payload
          })
        } else {
          return fetch.post(`/company`, {
            ...payload
          })
        }
      })
      .then(res => {
        message.warning('保存成功')
        router.push('/company')
      })
  }
}

onMounted(() => {
  if (route.params.id === 'new') {
    formData.value = {
      _id: '',
      name: '',
      desc: '',
      imageList: [],
      content: '',
      scale: '',
      financialStatus: '',
      industry: '',
      tagList: [],
      contactInfo: {
        address: '',
        tel: '',
        people: '',
        lat: '',
        lng: ''
      }
    }
  } else {
    fetch.get(`/company/${route.params.id}`).then(res => {
      formData.value = res
    })
  }
})
</script>

<style lang="less" scoped>
.addItem {
  .flexCenter();
  .border();
  width: 50px;
  height: 50px;
}
</style>
